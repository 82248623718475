@import "../functions/strip-unit";
@import "../functions/breakpoint";
@import "../settings/grid";

@mixin respond-up($breakpoint, $breakpoints: $grid-breakpoints) {
  $min: get-breakpoint($breakpoint, $breakpoints, up);

  @if (type_of($min) == number and $min > 0) {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin respond-down($breakpoint, $breakpoints: $grid-breakpoints) {
  $max: get-breakpoint($breakpoint, $breakpoints);

  @if (type_of($max) == number) {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin respond-between($from, $to, $breakpoints: $grid-breakpoints) {
  @include respond-up($from, $breakpoints) {
    @include respond-down($to, $breakpoints) {
      @content;
    }
  }
}

@mixin respond-only($breakpoint, $breakpoints: $grid-breakpoints) {
  @include respond-between($breakpoint, $breakpoint, $breakpoints: $grid-breakpoints) {
    @content;
  }
}

@mixin respond-portrait() {
  @media (orientation: portrait) {
    @content;
  }
}

@mixin respond-landscape() {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin respond-pixel-ratio($ratio: 1) {
  @if (type_of($ratio) != number) {
    @error "$ratio #{$ratio} has to be a Number!";
  }

  @if (unit($ratio) == dppx) {
    $ratio: strip-unit($ratio);
  }

  @if (unitless($ratio)) {
    $ratio: $ratio * 96dpi;
  }

  @if (unit($ratio) != dpi) {
    @error "$ratio #{$ratio} has to be of unit dppx or unitless!";
  }

  @media (min-resolution: $ratio) {
    @content;
  }
}
