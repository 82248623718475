@mixin clearfix() {
  *zoom: 1;

  &::before,
  &::after {
    display: table;
    content: " ";
  }

  &::after {
    clear: both;
  }
}
