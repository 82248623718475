@keyframes fadeOutRightInLeft {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  51% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}
