@import 'styles/mixins/grid';
@import 'styles/mixins/respond';
@import 'styles/mixins/typo';
@import 'styles/mixins/word-break';
@import 'components/m-link/mixins/link';

.m-page-title {
  @include make-container();
  @include make-container-max-widths();

  margin-top: 30px;

  text-align: center;

  &:first-child {
    margin-top: 0;
  }

  @include respond-up(md) {
    margin-top: 60px;

    &:first-child {
      margin-top: 0;
    }
  }

  @include respond-up(lg) {
    margin-top: 80px;

    &:first-child {
      margin-top: 0;
      // overwrite vertical rhythm for h1
      padding-top: 80px;
    }
  }
}

.m-page-title__heading {
  @include word-break();
  @include typo-page-title--publico();

  margin: 0;
}

.m-page-title__notice {
  @include word-break();
  @include typo-secondary-text();

  display: block;
  margin-top: 20px;

  a {
    @include link();
    @include link-deco();
    @include link-color();
  }

  @include respond-up(md) {
    margin-top: 40px;
  }
}
