@import 'styles/settings/colors';
@import 'styles/mixins/grid';
@import 'styles/mixins/icon';
@import 'styles/mixins/respond';
@import 'styles/mixins/typo';
@import 'styles/mixins/unstyle-list';
@import 'styles/mixins/word-break';
@import 'components/m-link/mixins/link';
@import 'components/m-button/mixins/button';

.m-contact-element {
  @include make-container();

  //max-width: $grid-content-maxwidth;
}

.m-contact-element__heading {
  @include word-break();
  @include typo-slice-title--publico();

  text-align: center;
  padding: 55px 0 40px;
  margin: 0;
}

.m-contact-element__list {
  @include unstyle-list();
  @include make-row(0);

  justify-content: center;
  align-items: stretch;
}

.m-contact-element__item {
  @include make-col-ready();
  @include make-col(12);

  display: flex;
  flex-direction: column;

  align-items: center;

  margin-top: 40px;

  &:first-child {
    margin-top: 0;
  }

  @include respond-up(400px) {
    @include make-col(6);

    &:nth-child(2) {
      margin-top: 0;
    }
  }

  @include respond-up(md) {
    @include make-col(4);
    margin-top: 0;
  }
}

.m-contact-element__icon-wrap {
  flex-grow: 0;
  justify-content: center;

  display: flex;
  align-items: center;
  flex-shrink: 0;

  height: 80px;
  width: 80px;
  border: 2px solid $color-axa-blue;
  border-radius:40px;

  background: $color-prim-white;

  margin: 0;
}

.m-contact-element__icon {
  @include icon(30px);

  color: $color-axa-blue;
}

.m-contact-element__title {
  @include typo-text--bold();

  margin: 25px 0 0;
  text-align: center;
}

.m-contact-element__reachability {
  @include typo-text-longer();

  margin: 10px 0 0;
  text-align: center;
}

.m-contact-element__action {
  @include button();
  @include button--fix-a-tag();
  @include button--color();
  @include button--ghost();

  margin: 30px 0 0;

  + & {
    margin-top: 20px;
  }
}

.m-contact-element__action--align {
  //margin-top: auto;
}

.m-contact-element__alternative {
  @include typo-legals();

  margin: 10px 0 0;
  text-align: center;
}
