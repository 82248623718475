@import 'styles/settings/colors';
@import 'styles/mixins/respond';
@import 'styles/mixins/grid';
@import 'styles/mixins/typo';
@import 'styles/mixins/word-break';
@import 'styles/mixins/clearfix';
@import 'components/m-link/mixins/link';

.m-text {
  @include make-container();
  @include make-container-max-widths();

  margin-top: 30px;

  .m-page-title + & {
    margin-top: 20px;

    @include respond-up(md) {
      margin-top: 60px;
    }
  }

  &:first-child {
    margin-top: 0;
  }
}

.m-text__content {
  @include typo-text-longer();
  @include make-col-ready();
  @include make-col(12);

  overflow: visible;

  p,
  li,
  h1,
  p > .heading--primary:first-child:last-of-type,
  h2,
  p > .heading--secondary:first-child:last-of-type,
  h3,
  p > .heading--tertiary:first-child:last-of-type
  h4,
  p > b:first-child:last-of-type {
    @include word-break();
  }

  p,
  ul,
  ol {
    margin: 15px 0 0;
  }

  h1,
  // todo: all content needs to be replaced with h1 and AEM Richtext needs to be configured for h1 only
  p > .heading--primary:first-child:last-of-type {
    @include word-break();
    @include typo-page-title--publico();

    margin: 30px 0 0;

    + * {
      margin-top: 20px;
    }

    @include respond-up(md) {
      margin-top: 60px;

      + * {
        margin-top: 40px;
      }
    }

    @include respond-up(lg) {
      margin-top: 80px;

      + * {
        margin-top: 60px;
      }
    }
  }

  h2,
  // todo: all content needs to be replaced with h2 and AEM Richtext needs to be configured for h2 only
  p > .heading--secondary:first-child:last-of-type {
    @include word-break();
    @include typo-slice-title--publico();

    margin: 30px 0 0;

    + * {
      margin-top: 20px;
    }

    @include respond-up(md) {
      margin-top: 60px;

      + * {
        margin-top: 40px;
      }
    }

    @include respond-up(lg) {
      margin-top: 80px;

      + * {
        margin-top: 60px;
      }
    }
  }

  h3,
  // todo: all content needs to be replaced with h3 and AEM Richtext needs to be configured for h3 only
  p > .heading--tertiary:first-child:last-of-type {
    @include word-break();
    @include typo-small-module-title();

    margin: 50px 0 0;

    + * {
      margin-top: 20px;
    }

    @include respond-up(md) {
      margin-top: 60px;
    }

    @include respond-up(lg) {
      margin-top: 70px;

      + * {
        margin-top: 30px;
      }
    }
  }

  // todo: all content needs to be replaced with h4 and AEM Richtext needs to be configured for h4
  h4 {
    @include word-break();
    @include typo-title();

    margin: 50px 0 0;

    + * {
      margin-top: 20px;
    }

    @include respond-up(md) {
      margin-top: 60px;
    }

    @include respond-up(lg) {
      margin-top: 70px;

      + * {
        margin-top: 30px;
      }
    }
  }

  // todo: needed to quickfix wrong content markup
  p > .heading--primary:first-child:last-of-type,
  p > .heading--secondary:first-child:last-of-type,
  p > .heading--tertiary:first-child:last-of-type,
  p > b:first-child:last-of-type {
    display: inline-block;
  }

  ul,
  ol {
    padding-left: 20px;

    ul,
    ol {
      margin-top: 10px;
      padding-left: 30px;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  a {
    @include link();
    @include link-deco();
    @include link-color();
  }

  :not(.expandable-container__content) > .m-text > & > :first-child {
    margin-top: 0;

    // @todo: temporary hack to support wrong content markup -> fix all contents first, then kill this
    & > .heading--primary:first-child:last-of-type,
    & > .heading--secondary:first-child:last-of-type,
    & > .heading--tertiary:first-child:last-of-type,
    & > b:first-child:last-of-type {
      margin-top: 0;
    }
  }
}

.m-text__content--center {
  @include respond-up(md) {
    display: flex;
    flex-direction: column;

    align-items: center;
  }
}

.m-text__content--lead {
  @include typo-text();
}

// @todo: only used at search page prototype - no design yet
.m-text__content--subtle {
  color: $color-prim-gray-mine-shaft;
}

// @todo: various content still uses the old class, after content is fixed remove this.
.text__content--narrow,
.m-text__content--narrow {
  @include respond-up(md) {
    @include make-col(10);
    @include make-col-offset(1);

    > h2:first-child {
      @include escape-col(1, 10);
    }
  }

  @include respond-up(lg) {
    @include make-col(8);
    @include make-col-offset(2);

    > h2:first-child {
      @include escape-col(2, 8);
    }
  }
}

// @todo: only used at search page prototype - no design yet
.m-text__content--narrower {
  @include respond-up(md) {
    @include make-col(6);
    @include make-col-offset(3);
  }
}

.m-text__content--white {
  color: $color-prim-white;
}

.m-text__content--gap {
  p {
    margin: 30px 0 0;
  }
}
