@import 'styles/settings/colors';
@import 'styles/mixins/grid';
@import 'styles/mixins/icon';
@import 'styles/mixins/respond';
@import 'styles/mixins/typo';
@import 'styles/mixins/word-break';
@import 'components/m-link/mixins/link';

.m-error {
  @include make-container();
  @include make-container-max-widths();

  color: $color-prim-gray-mine-shaft;
  text-align: center;

  padding: 25px 15px;

  &:last-child {
    margin-bottom: 0;
  }

  @include respond-up(md) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.m-error__heading {
  @include word-break();
  @include typo-slice-title--publico();

  margin: 0;
  color: inherit;
}

.m-error__message,
.m-error__greet {
  @include typo-text();

  margin: 15px 0 0;

  .m-error__heading + & {
    margin-top: 30px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.m-error__cta {
  @include link();

  vertical-align: middle;
}

.m-error__icon {
  @include icon(25px);

  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  color: $color-prim-gray-scorpion;

  .m-error__cta:hover &,
  .m-error__cta:focus & {
    color: inherit;
  }
}
