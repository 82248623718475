@import "settings/header-logo";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/icon";
@import "../../styles/mixins/respond";

.m-header-logo {
  @include make-col(2);

  display: block;

  align-self: center;

  @include respond-up(lg) {
    align-self: flex-start;
  }
}

.m-header-logo__link {
  display: block;
}

.m-header-logo__icon {
  @include icon-size($header-logo-size);

  display: block;

  @include respond-up(sm) {
    @include icon-size($header-logo-size--md);
  }

  @include respond-up(xl) {
    @include icon-size($header-logo-size--xl);
  }
}

.m-header-logo__img {
  display: block;

  height: $header-logo-size;

  @include respond-up(sm) {
    height: $header-logo-size--md;
  }

  @include respond-up(xl) {
    height: $header-logo-size--xl;
  }
}
