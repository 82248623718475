@import 'styles/settings/colors';
@import 'styles/settings/grid';
@import 'styles/mixins/grid';
@import 'styles/mixins/icon';
@import 'styles/mixins/respond';
@import 'styles/mixins/typo';
@import 'styles/mixins/unstyle-list';
@import 'styles/mixins/word-break';
@import 'components/m-link/mixins/link';

$list2-border-size: 1px !default;

$list2__item-padding-y: 20px !default;
$list2__figure-size: 40px !default;
$list2__icon-size: 15px !default;

.list2 {
  padding-bottom: $list2-border-size;
}

.list2__item {
  $padding-right: $list2__icon-size + $grid-gutter-width;

  @include typo-text--semibold();
  @include link-color();

  position: relative;
  display: block;

  padding: $list2__item-padding-y $padding-right $list2__item-padding-y ($grid-gutter-width / 2);
  border-top: $list2-border-size solid $color-prim-gray-mercury;
  border-bottom: $list2-border-size solid $color-prim-gray-mercury;
  margin-bottom: -$list2-border-size;

  @include respond-up(sm) {
    padding-left: 0;
    padding-right: $padding-right - ($grid-gutter-width / 2);
  }
}

.list2__item--unpadded {
  @include make-gutter(0);
}

.list2__figure,
.list2__icon,
.list2__text {
}

.list2__figure {
  display: block;
  width: $list2__figure-size;
  height: $list2__figure-size;
  margin-right: $grid-gutter-width/2;

  color: $color-axa-blue;
}

.list2__icon {
  @include icon($list2__icon-size);

  position: absolute;
  top: 50%;
  right: ($grid-gutter-width / 2);

  margin-top: -($list2__icon-size/2);

  transform: rotate(-90deg);

  @include respond-up(sm) {
    right: 0;
  }
}

.list2__text {
  @include word-break();
  @include typo-secondary-text--semibold();
}
