/// Base path of font location.
/// In case this changes for you just ovverride it before importing.
$font-base-path: './assets/fonts/' !default;

/// AXA use two font faces - Publico Headline and Source Sans Pro.
///
/// @link https://design.axa.com/web-guidelines/typography#overview
$face-map:
  (
    source-sans-pro: ("Source Sans Pro", Arial, sans-serif),
    publico: ("Publico Headline", Georgia, serif),
  ) !default;

/// AXA global typography's typeface variation map.
///
/// @link https://design.axa.com/web-guidelines/typography#properties
$typo-map:
  (
    // small -> mobile
    sm: (
      typo-78--publico: ( size: 46px, weight: Bold, spacing: 0.02em, lineHeight: 1.13, paddingBottom: 21px ),
      typo-62--publico: ( size: 30px, weight: Bold, spacing: 0.02em, lineHeight: 1.13, paddingBottom: 16px ),
      typo-48--publico: ( size: 24px, weight: Bold, spacing: 0.02em, lineHeight: 1.17, paddingBottom: 13px ),
      typo-36--publico: ( size: 20px, weight: Bold, spacing: 0.02em, lineHeight: 1.5, paddingBottom: 11px ),
      typo-78: ( size: 46px, weight: Bold, spacing: 0.01em, lineHeight: 1.13, paddingBottom: 21px ),
      typo-62: ( size: 30px, weight: Bold, spacing: 0.01em, lineHeight: 1.13, paddingBottom: 16px ),
      typo-48: ( size: 24px, weight: Bold, spacing: 0.01em, lineHeight: 1.17, paddingBottom: 13px ),
      typo-36: ( size: 20px, weight: Bold, spacing: 0.01em, lineHeight: 1.5, paddingBottom: 11px ),
      typo-28: ( size: 20px, weight: (Bold, Semibold), spacing: 0.01em, lineHeight: 1.5, paddingBottom: 16px ),
      typo-24: ( size: 20px, weight: Bold, spacing: 0, lineHeight: 1.5, paddingBottom: 11px ),
      typo-20: ( size: 18px, weight: (Regular, Semibold, Bold), spacing: 0.01em, lineHeight: 1.5, paddingBottom: 12px ),
      typo-18: ( size: 16px, weight: (Regular, Bold), spacing: 0.01em, lineHeight: 1.5, paddingBottom: 10px ),
      typo-16: ( size: 14px, weight: (Regular, Semibold, Bold), spacing: 0.02em, lineHeight: 1.5, paddingBottom: 10px ),
      typo-14: ( size: 12px, weight: (Regular, Semibold, Bold), spacing: 0.02em, lineHeight: 1.5, paddingBottom: 10px ),
      typo-13: ( size: 12px, weight: (Regular, Semibold), spacing: 0.02em, lineHeight: 1.5, paddingBottom: 10px ),
    ),
    // medium -> tablet
    md: (
      typo-78--publico: ( size: 78px, weight: Bold, spacing: 0.02em, lineHeight: 1.05, paddingBottom: 28px ),
      typo-62--publico: ( size: 62px, weight: Bold, spacing: 0.02em, lineHeight: 1.16, paddingBottom: 26px ),
      typo-48--publico: ( size: 48px, weight: Bold, spacing: 0.02em, lineHeight: 1.13, paddingBottom: 18px ),
      typo-36--publico: ( size: 36px, weight: Bold, spacing: 0.02em, lineHeight: 1.17, paddingBottom: 17px ),
      typo-78: ( size: 78px, weight: Bold, spacing: 0.01em, lineHeight: 1.05, paddingBottom: 28px ),
      typo-62: ( size: 62px, weight: Bold, spacing: 0.01em, lineHeight: 1.16, paddingBottom: 26px ),
      typo-48: ( size: 48px, weight: Bold, spacing: 0.01em, lineHeight: 1.13, paddingBottom: 18px ),
      typo-36: ( size: 36px, weight: Bold, spacing: 0.01em, lineHeight: 1.17, paddingBottom: 17px ),
      typo-28: ( size: 28px, weight: (Bold, Semibold), spacing: 0.01em, lineHeight: 1.14, paddingBottom: 13px ),
      typo-24: ( size: 24px, weight: Bold, spacing: 0, lineHeight: 1.2, paddingBottom: 13px ),
      typo-20: ( size: 20px, weight: (Regular, Semibold, Bold), spacing: 0.01em, lineHeight: 1.5, paddingBottom: 16px ),
      typo-18: ( size: 18px, weight: (Regular, Bold), spacing: 0.01em, lineHeight: 1.5, paddingBottom: 15px ),
      typo-16: ( size: 16px, weight: (Regular, Semibold, Bold), spacing: 0.02em, lineHeight: 1.5, paddingBottom: 11px ),
      typo-14: ( size: 14px, weight: (Regular, Semibold, Bold), spacing: 0.02em, lineHeight: 1.21, paddingBottom: 8px ),
      typo-13: ( size: 13px, weight: (Regular, Semibold), spacing: 0.02em, lineHeight: 1.38, paddingBottom: 9px ),
    ),
    // large -> small desktop
    lg: (
      typo-78--publico: ( size: 78px, weight: Bold, spacing: 0.02em, lineHeight: 1.05, paddingBottom: 28px ),
      typo-62--publico: ( size: 62px, weight: Bold, spacing: 0.02em, lineHeight: 1.16, paddingBottom: 26px ),
      typo-48--publico: ( size: 48px, weight: Bold, spacing: 0.02em, lineHeight: 1.13, paddingBottom: 18px ),
      typo-36--publico: ( size: 36px, weight: Bold, spacing: 0.02em, lineHeight: 1.17, paddingBottom: 17px ),
      typo-78: ( size: 78px, weight: Bold, spacing: 0.01em, lineHeight: 1.05, paddingBottom: 28px ),
      typo-62: ( size: 62px, weight: Bold, spacing: 0.01em, lineHeight: 1.16, paddingBottom: 26px ),
      typo-48: ( size: 48px, weight: Bold, spacing: 0.01em, lineHeight: 1.13, paddingBottom: 18px ),
      typo-36: ( size: 36px, weight: Bold, spacing: 0.01em, lineHeight: 1.17, paddingBottom: 17px ),
      typo-28: ( size: 28px, weight: (Bold, Semibold), spacing: 0.01em, lineHeight: 1.14, paddingBottom: 13px ),
      typo-24: ( size: 24px, weight: Bold, spacing: 0, lineHeight: 1.2, paddingBottom: 13px ),
      typo-20: ( size: 20px, weight: (Regular, Semibold, Bold), spacing: 0.01em, lineHeight: 1.5, paddingBottom: 16px ),
      typo-18: ( size: 18px, weight: (Regular, Bold), spacing: 0.01em, lineHeight: 1.5, paddingBottom: 15px ),
      typo-16: ( size: 16px, weight: (Regular, Semibold, Bold), spacing: 0.02em, lineHeight: 1.5, paddingBottom: 11px ),
      typo-14: ( size: 14px, weight: (Regular, Semibold, Bold), spacing: 0.02em, lineHeight: 1.21, paddingBottom: 8px ),
      typo-13: ( size: 13px, weight: (Regular, Semibold), spacing: 0.02em, lineHeight: 1.38, paddingBottom: 9px ),
    ),
    // extra large -> full desktop
    xl: (
      typo-78--publico: ( size: 78px, weight: Bold, spacing: 0.02em, lineHeight: 1.05, paddingBottom: 28px ),
      typo-62--publico: ( size: 62px, weight: Bold, spacing: 0.02em, lineHeight: 1.16, paddingBottom: 26px ),
      typo-48--publico: ( size: 48px, weight: Bold, spacing: 0.02em, lineHeight: 1.13, paddingBottom: 18px ),
      typo-36--publico: ( size: 36px, weight: Bold, spacing: 0.02em, lineHeight: 1.17, paddingBottom: 17px ),
      typo-78: ( size: 78px, weight: Bold, spacing: 0.01em, lineHeight: 1.05, paddingBottom: 28px ),
      typo-62: ( size: 62px, weight: Bold, spacing: 0.01em, lineHeight: 1.16, paddingBottom: 26px ),
      typo-48: ( size: 48px, weight: Bold, spacing: 0.01em, lineHeight: 1.13, paddingBottom: 18px ),
      typo-36: ( size: 36px, weight: Bold, spacing: 0.01em, lineHeight: 1.17, paddingBottom: 17px ),
      typo-28: ( size: 28px, weight: (Bold, Semibold), spacing: 0.01em, lineHeight: 1.14, paddingBottom: 13px ),
      typo-24: ( size: 24px, weight: Bold, spacing: 0, lineHeight: 1.2, paddingBottom: 13px ),
      typo-20: ( size: 20px, weight: (Regular, Semibold, Bold), spacing: 0.01em, lineHeight: 1.5, paddingBottom: 16px ),
      typo-18: ( size: 18px, weight: (Regular, Bold), spacing: 0.01em, lineHeight: 1.5, paddingBottom: 15px ),
      typo-16: ( size: 16px, weight: (Regular, Semibold, Bold), spacing: 0.02em, lineHeight: 1.5, paddingBottom: 11px ),
      typo-14: ( size: 14px, weight: (Regular, Semibold, Bold), spacing: 0.02em, lineHeight: 1.21, paddingBottom: 8px ),
      typo-13: ( size: 13px, weight: (Regular, Semibold), spacing: 0.02em, lineHeight: 1.38, paddingBottom: 9px ),
    ),
  ) !default;

/// Common font weight name mapping.
///
/// @link https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#Common_weight_name_mapping
/// @link here: http://www.w3.org/TR/css3-fonts/#font-weight-numeric-values
///
/// @TODO: probably replace by https://www.npmjs.com/package/postcss-font-weight-names
$font-weight-map:
  (
    Thin: 100,
    Extralight: 200,
    Ultralight: 200,
    Light: 300,
    Normal: 400,
    Book: 400,
    Regular: 400,
    Medium: 500,
    Semibold: 600,
    Demibold: 600,
    Bold: 700,
    Extrabold: 800,
    Ultrabold: 800,
    Black: 900,
    Heavy: 900,
  ) !default;
