@import "./settings/footer-sub";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/respond";

.m-footer-sub {
  display: block;

  background: $footer-sub-bg;
  border-top: $footer-sub-border-top-width solid $footer-sub-border-top-color;
}

.m-footer-sub__box {
  @include make-container();
  @include make-container-max-widths();
}

.m-footer-sub__row {
  @include make-row();

  align-items: center;
  justify-content: space-between;

  @include respond-down(md) {
    flex-direction: column;
  }
}
