@import 'styles/settings/colors';
@import 'styles/mixins/grid';
@import 'styles/mixins/respond';
@import 'styles/mixins/typo';
@import 'styles/mixins/unstyle-list';
@import 'styles/mixins/word-break';
@import 'styles/mixins/vertical-rhythm';
@import './m-list';

$simple-overview__row-padding-bottom: 60px !default;
$simple-overview__row-padding-bottom--lg: 100px !default;

.m-simple-overview {
  @include make-container(0);
  @include make-container-max-widths();
  @include vr-margin-item();
}

.m-simple-overview__row {
  @include unstyle-list();
  @include make-row(0);

  &:first-child {
    margin-top: -$simple-overview__row-padding-bottom;
  }

  @include respond-up(lg) {
    &:first-child {
      margin-top: -$simple-overview__row-padding-bottom--lg;
    }
  }
}

.m-simple-overview__item {
  @include make-col-ready(0);
  @include make-col(12);

  margin-top: $simple-overview__row-padding-bottom;

  @include respond-up(lg) {
    @include make-col(5);

    margin-top: $simple-overview__row-padding-bottom--lg;

    &:nth-child(2n) {
      @include make-col-offset(2);
    }
  }
}

.m-simple-overview__item--centered {
  @include respond-up(lg) {
    @include make-col(6);
    @include make-col-offset(3);
  }
}

.m-simple-overview__item__heading {
  @include word-break();
  @include typo-small-module-title--publico();

  display: flex;

  margin: 0;

  align-items: flex-end;
  justify-content: center;
  text-align: center;
}

.m-simple-overview__item__list {
  margin-top: 30px;
  border-top: 3px solid $color-prim-gray-mercury;

  @include respond-up(lg) {
    margin-top: 25px;
  }
}

.m-simple-overview__item__list__link {
  text-transform: uppercase;
}
