@import "./settings/footer-languages";
@import "../../styles/mixins/clearfix";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/respond";
@import "../../styles/mixins/typo";
@import "../../styles/mixins/unstyle-list";
@import "../../styles/mixins/word-break";

.m-footer-languages {
  @include make-col-ready();
  @include make-col(4);

  display: block;

  @include respond-down(md) {
    @include reset-col();
  }
}

.m-footer-languages__title {
  @include word-break();
  @include typo-secondary-text--bold();

  color: $footer-languages__title-color;
  text-transform: uppercase;

  // never show title on phones and phablets
  @include respond-down(md) {
    display: none !important;
  }
}

.m-footer-languages__list {
  @include unstyle-list();
  @include clearfix();
  @include revert-gutter(20px);
}

.m-footer-languages__list-item {
  position: relative;

  display: block;
  float: left;

  // delimiter
  &::after {
    position: absolute;
    left: 0;
    top: 50%;

    display: block;
    width: $footer-delimiter-width;
    height: $footer-delimiter-height;
    margin-top: ($footer-delimiter-height / -2);

    content: '';
    background: $footer-delimiter-bg;
  }

  &:first-child::after {
    display: none;
  }
}

.m-footer-languages__link {
  $padding-top: ($footer-languages__link-y-padding - $footer-border-width);
  $padding-x: $footer-languages__link-x-padding;
  $padding-bottom: $footer-languages__link-y-padding;

  @include typo-tag--semibold();

  display: block;
  padding: $padding-top $padding-x $padding-bottom;

  color: $footer-languages__link-color;
  text-transform: uppercase;

  &:hover,
  &:active,
  &.is-footer-languages-active {
    color: $footer-languages__link-color--hover;
    text-decoration: none;
  }
}

.m-footer-languages--inline {
  @include reset-col();

  .m-footer-languages__box {
    display: flex;

    align-items: center;
  }

  .m-footer-languages__title {
    @include typo-tag--bold();

    display: block;

    margin-right: $footer-languages__title-margin-right--inline;

    // inline always with colon at end of title
    &::after {
      content: ':';
    }
  }
}
