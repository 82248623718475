$vr-base: 10px !default;

$vr-padding-tops:
  (
    // Extra small screen / phone
    xs: $vr-base * 4, // 40px
    // Small screen / phone
    // sm: 40px,
    // Medium screen / tablet
    md: $vr-base * 6, // 60px
    // Large screen / desktop
    lg: $vr-base * 7, // 70px
    // Extra large screen / wide desktop
    // xl: 70px
  ) !default;

$vr-padding-bottoms:
  (
    // Extra small screen / phone
    xs: $vr-base * 4, // 40px
    // Small screen / phone
    // sm: 40px,
    // Medium screen / tablet
    md: $vr-base * 6, // 60px
    // Large screen / desktop
    // lg: 60px,
    // Extra large screen / wide desktop
    // xl: 60px
  ) !default;

$vr-margin-tops:
  (
    // Extra small screen / phone
    xs: $vr-base * 2, // 20px
    // Small screen / phone
    // sm: 20px,
    // Medium screen / tablet
    md: $vr-base * 4, // 40px
    // Large screen / desktop
    lg: $vr-base * 6, // 60px
    // Extra large screen / wide desktop
    // xl: 60px
  ) !default;
