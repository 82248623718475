@import 'styles/settings/colors';
@import 'styles/mixins/respond';

.m-page-section {
}

// @todo: existing content needs to be migrated to use new classes
.page-section--graybackground,
.m-page-section--graybackground {
  background: $color-prim-gray-wild-sand;
}

.page-section--bluebackground,
.m-page-section--bluebackground {
  background: $color-axa-blue;
}

.page-section--padded,
.m-page-section--padded {
  // Consistent vertical-rhythm with opt-out for child components.
  // Basically the always the first child get top padding and the last child bottom padding respectively
  // This way every child component has the chance to override that basic vertical rhythm (opt-out)
  > :first-child {
    padding-top: 40px;
  }

  > :last-child {
    padding-bottom: 40px;
  }

  @include respond-up(md) {
    > :first-child {
      padding-top: 60px;
    }

    > :last-child {
      padding-bottom: 60px;
    }
  }

  @include respond-up(lg) {
    > :first-child {
      padding-top: 60px;
    }

    > :last-child {
      padding-bottom: 70px;
    }
  }
}
